ul.page-numbers{
    list-style: none;
    margin-top: 1rem;

    li {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;

        a {
            color: $theme-color-by!important;
        }

        & > span.current{
            display: block;
            color: $theme-color-wb;
            background: $theme-color-vy;
        }
    }
}

.alignleft, img.alignleft {
	margin-right: 1.5em;
	display: inline;
	float: left;

}

.alignright, img.alignright {
	margin-left: 1.5em;
	display: inline;
	float: right;

}

.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
	clear: both;
}

.wpcf7-not-valid-tip {
    font-size: 10px !important;
}

.wpcf7-response-output {
    font-size: 10px !important;
    margin: 0.5em auto 1em !important;
}

.wpcf7-acceptance {
    .wpcf7-list-item {
        margin: 0;

        label {

            input {
                position: relative;
                width: 20px;
                height: 20px;
                top: 5px;
                margin-right: 5px;
            }

            a {
                color: #fff;
            }
        }
    }
}

.grecaptcha-badge {
    z-index: 9999;
}