.footer {
    overflow: hidden;
    margin-top: .5rem;
    margin-bottom: 3rem;
    
    &__spacer {
        height: 50px;
        
        &::before {
            content: '';
            position: absolute;
            @include fillit(12,12,12,12,12,0);
            @include single-negativ-margin(right);
            height: 50px;
            background: $theme-color-vy;
            
            @include media-breakpoint-up(md){
                @include fillit(12);
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 10px;
            border-top: 70px solid $theme-color-wb;
            border-right: 20px solid transparent;
            
            @include media-breakpoint-up(md){
                left: 0;
            }
        }
    }

    &__info {
        color: $theme-color-by;

        a {
            color: $theme-color-vy;
        }
    }

    &__nav {
        position: relative;
        min-height: 440px;
        font-weight: 300;
        line-height: 150%;
        color: $theme-color-wb;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            min-height: 440px;
            @include fillit(9,9,9,9,9,-15);
            @include single-negativ-margin(right);
            background: $theme-color-by;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-top: 440px solid $theme-color-wb;
            border-right: 80px solid transparent;
        }

        ul {
            li {
                transition: transform .3s ease-in-out;
                &:hover {
                    transform: translateX(5px);
                }
            }
        }
    }

    &__social {
        position: relative;
        min-height: 200px;
        padding: 70px 0 0 100px !important;
        z-index: 9;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            min-height: 200px;
            @include fillit(3,3,6,6,12,-32);
            @include single-negativ-margin(right);
            background: $theme-color-vb;
            z-index: -1;
        }

        
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -20px;
            border-top: 220px solid $theme-color-wb;
            border-right: 40px solid transparent;
            z-index: -1;
            
            @include media-breakpoint-up(lg){
                left: -10px;
                border-top: 200px solid $theme-color-by;
            }
            @include media-breakpoint-up(xl){
                left: -4px;
            }
        }

        .icons {
            a {
                display: inline-block;
                margin-right: 0.5rem;
                
                @include media-breakpoint-up(xl){
                    margin-right: 1.2rem;
                }

                svg {
                    fill: $theme-color-wy;
                }
            }  
        }

        &--mobile {
            padding: 80px 0 0 20px !important;
            
            
            @include media-breakpoint-up(sm){
                padding: 70px 0 0 70px !important;
            }

            .logo {
                margin-left: -10px;
                
                @include media-breakpoint-up(sm){
                    margin-left: -70px;
                }
            }
        }

    }
}