.comunique {
    background: $theme-color-gy;

    &__header {
        position: relative;
        display: flex;
        top: 0em;
        justify-content: space-between;
        min-height: 23px;
        color: $theme-color-bb;

        .degree {
            a {
                display: inline-block;
                width: 25px;
                height: 20px;
                border: 1px solid currentColor;
                font-weight: 500;
                text-align: center;
                line-height: 120%;
                margin-right: 5px;
            }
        }

        .type {
            span {
                display: inline-block;
                width: 25px;
                height: 20px;
                border: 1px solid currentColor;
                font-weight: 500;
                text-align: center;
                line-height: 120%;
                margin-left: 5px;
            }
        }
    }

    &__navbar {
        font-size: 1.25em;
        color: $theme-color-by;
    }

    .date {
        font-size: 0.85em;
        color: $theme-color-vb;
        margin-top: .4rem;
        margin-bottom: .8rem;
    }
    
    &__body {
        font-size: 1.14em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        font-weight: 500;
        color: $theme-color-vb;
        line-height: 130%;
        height: 110px;
        text-align: center;
    }
    
    &__footer {
        position: relative;
        font-size: 1.14em;
        text-align: center;
        height: 40px;
        line-height: 40px;
        background: $theme-color-vb;
        color: $theme-color-wy;
        z-index: 8;

        .level {
            position: absolute;
            top: 0;
            height: 100%;
            font-size: 14px;
            width: 37px;
            padding-right: 5px;
            right: 0;
            z-index: 9;

            &::before {
                content: '';
                position: absolute;
                width: 50px;
                right: 0;
                height: 40px;
                z-index: 0;
                border-bottom: 40px solid $theme-color-bb;
                border-left: 15px solid transparent;
                z-index: -1;
            }
        }
    }
}