.card {
    &__header {
        color: $theme-color-vy;
    }

    &__body {
        overflow: hidden;
    }
    
    &__img {
        height: 200px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .8s cubic-bezier(0.375, 0.885, 0.52, 1);
    }

    &__footer {
        margin-top: 1px;

        .grade {
            @extend .btn;
            width: 49.6%;
            min-height: 40px;
            color: $theme-color-wb;

            &--first {
                background: $theme-color-by;
                float: left;
            }
            &--second {
                background: $theme-color-vy;
                float: right;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:hover {
                color: $theme-color-wb;
            }
        }

        &--small {
            padding: .5rem;
            text-align: center;
            color: $theme-color-wb;
            font-weight: 500;
            background: $theme-color-by;
            margin-bottom: 2.5rem;
        }
    }

    &:hover {
        .card__img {
            transform: scale(1.15) rotate(-5deg);
        }
    }
}