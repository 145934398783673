.hero {
    height: 400px;
    
    @include media-breakpoint-up(md){
        height: 500px;
    }
    
    &__slider {
        position: relative;
    }
    
    &__slide {
        height: 400px;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;

        @include media-breakpoint-up(md){
            height: 500px;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            right: 0;
            width: 100%;
            height: 200px;
            bottom: 0;
            background: rgba($theme-color-bb, 0.6);
            z-index: 1;
            
            @include media-breakpoint-up(md){
                @include fillit(5,6,8,9,5,0);
                height: 100%;
                bottom: auto;
                background: transparent;
                border-bottom: 500px solid rgba($theme-color-bb, 0.6);
                border-left: 140px solid transparent;
            }
        }
    }

    &__title {
        font-size: 2em;
        line-height: 140%;
        text-align: right;
        color: $theme-color-wy;
        position: relative;
        z-index: 999;
        
        @include media-breakpoint-up(md){
            font-size: 2.5em;
        }
    }
}