.section {
    overflow: hidden;

    &__title {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100px;
        color: $theme-color-wb;
        // padding-left: 40px;
        
        @include media-breakpoint-down(sm){
            padding-left: 45px;
            h3 {
                font-size: 1.5em;
            }
        }
        
        &--violet {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                height: 100px;
                @include fillit(5,6,8,11,11,-5);
                @include single-negativ-margin(right, 15);
                background: $theme-color-vy;
                z-index: -1;
            }
            &::after {
                content: '';
                position: absolute;
                width: 25px;
                top: 0;
                left: 18px;
                border-bottom: 100px solid $theme-color-vy;
                border-left: 15px solid transparent;
                
                @include media-breakpoint-up(xs){
                    width:40px;
                }
                @include media-breakpoint-up(md){
                    width: 55px;
                    border-left: 30px solid transparent;
                    left: -40px;
                }
            }

        }

        &--blue {
            &::before {
                content: '';
                position: absolute;
                height: 100px;
                @include fillit(4,4,8,11,11,-5);
                @include single-negativ-margin(right);
                background: $theme-color-by;
                z-index: -1;
            }
            &::after {
                content: '';
                position: absolute;
                width: 55px;
                top: 0;
                left: 18px;
                border-bottom: 100px solid $theme-color-by;
                border-left: 15px solid transparent;
                
                @include media-breakpoint-up(md){
                    border-left: 30px solid transparent;
                    left: -40px;
                }
            }
        }
    }

    &--wyswig {
        color: $theme-color-dd;
        a {
            color: $theme-color-vy;
        }

        table {
            @extend .table;
            @extend .table-striped;
            @extend .table-borderless;

            color: $theme-color-by;
        }

        ul {
            list-style: disc;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    &--search {
        ul {
            li {
                a {
                    font-weight: 600;
                    color: $theme-color-vy;
                }
            }
        }
    }
}