.navbar {
    padding: .5rem 0;
    
    @include media-breakpoint-up(xl){
        padding: 0 1rem;
        height: 75px;

        .navbar-nav,
        .collapse {
            height: 100%;
        }
    }

    .navbar-brand {
        max-width: 170px;
        
        @include media-breakpoint-up(sm){
            max-width: 240px;
        }
    }

    .navbar-toggler {
        padding: .55rem .75rem;
        border-radius: 0;
        background: $theme-color-by;

        svg path{
            fill: $theme-color-wb;
        }
    }

    .nav-item {
        cursor: pointer;
        
        @include media-breakpoint-up(xl){
            margin-right: 25px;
            line-height: 60px;
        }
        
        .nav-link {
            position: relative;
            font-size: 1.25em;
            font-weight: 500;
            color: $theme-color-dd;
                
                @include media-breakpoint-up(xl){
                    
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 15px;
                        width: 100%;
                        max-width: 100px;
                        margin: auto;
                        height: 2px;
                        background: $theme-color-by;
                        opacity: 0;
                        transform: translateY(4px);
                        transition: all .2s cubic-bezier(0.215, 0.610, 0.355, 1);
                    }
                }
            
            &:active,
            &:hover {
                .nav-link {
                    color: $theme-color-vy;
                }
                &::before {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }


    }

    .dropdown-menu {
        margin-top: 0;
        
        @include media-breakpoint-up(xl){
            position: absolute !important;
        }

        &.mega-menu {
            padding: 1rem 1.5rem;
            border: none;
            color: #fff;
            background: $theme-color-by;
            border-radius: 0;
            line-height: 200%;
            text-align: center;
            
            @include media-breakpoint-up(xl){
                @include fillit(9,9,9,9,9,24);
                height: 500px;
                text-align: left;
                line-height: 220%;
                padding: 2rem 1rem;
                background: rgba($theme-color-by, 0.8);
                animation-duration: 0.4s;
                animation-name: slideNav;
                    -webkit-animation-name: slideNav;
                animation-fill-mode: both;
            }

            a {
                color: $theme-color-wb;
                display: block;
                transition: transform .3s ease-in-out;

                &:hover {
                    transform: translateX(5px);
                }
            }
        }

    }

    .dropdown-mega {
        position: static;

        .dropdown-toggle::after {
            display: none;
        }

    }
}