.news {
    margin-bottom: 1.5rem;
    
    @include media-breakpoint-up(md){
        margin-bottom: 2rem;
    }

    &__body {
        position: relative;
        overflow: hidden;
        h5 {
            font-size: 1.25em;
            margin-top: 1.5rem;
            color: $theme-color-vy;
        }
    }

    &__date {
        position: absolute;
        top: 0;
        right: 0;
        background: $theme-color-vy;
        color: $theme-color-wb;
        padding: .4rem .8rem;
        z-index: 99;
    }

    &__img {
        height: 150px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .8s cubic-bezier(0.375, 0.885, 0.32, 1);
    }

    &:hover {
        .news__img {
            transform: scale(1.1);
        }
    }
}