.prenavbar {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 1rem 0 3rem;
    height: 60px;
    display: none;

    @include media-breakpoint-up(xl){
        display: inline-flex;
    }

    svg {
        fill: $theme-color-wb;
    }

    &__links {
        display: flex;
        align-items: center;
        color: $theme-color-wb;
        
        a {
            position: relative;
            color: $theme-color-wb;
            cursor: pointer;
            padding-right: 15px;
            line-height: 110%;
            transition: all .3s ease-in-out;

            @include media-breakpoint-up(xl){
                    
                &:hover {
                    transform: translateY(-4px);
                }
            }
        }
    }

    &__search {
        position: absolute;
        display: none;
        width: 500px;

        input {
            font-size: 1em;
            background: transparent;
            color: $theme-color-wb !important;
            border: none;
            
            &:focus {
                outline: none;
                background-color: transparent;
                box-shadow: none;
            }
            &::placeholder {
                color: $theme-color-wb !important;
            }
        }
    }

    &__sizes {
        position: relative;
        display: flex;
        color: $theme-color-dd !important;
        align-items: baseline;
        padding: 0 25px;
        margin: auto 5px;
        line-height: 40px;
        
        @include media-breakpoint-up(xl){
            line-height: 60px;
            color: $theme-color-wb !important;
        }

        &--mobile {
            display: block;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 1px;
            height: 24px;
            background: $theme-color-wb;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 1px;
            height: 24px;
            background: $theme-color-wb;
        }

        .size {
            cursor: pointer;
            padding-right: 4px;

            &--a {
                font-size: 12px;
            }
            &--aa {
                font-size: 14px;
            }
            &--aaa {
                font-size: 16px;
            }
        }

        .contrast {
            cursor: pointer;
            position: relative;
            display: flex;
            align-self: center;
            overflow: hidden;
            width: 24px;
            height: 24px;
            border-radius: 50px;
            border: 2px solid $theme-color-dd;
            z-index: 9;
            margin-left: 15px;
            transition: transform .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

            &--mobile{
                display: inline-block;
            }
            
            @include media-breakpoint-up(xl){
                border: 2px solid $theme-color-wb;
            }

            &::before {
                content: '';
                position: absolute;
                width: 30px;
                height: 30px;
                top: 0;
                bottom: 0;
                left: 11px;
                background: $theme-color-dd;
                z-index: 99;
                
                @include media-breakpoint-up(xl){
                    background: $theme-color-wb;
                }
            }

            &:hover{
                transform: rotate(180deg);
            }
        }
    }
    &__languages {
        position: relative;
        width: 20px;
        margin-left: 10px;

        a {
            cursor: pointer;

            &.current {
                position: relative;

                &::before {
                    content: '\2304';
                    position: absolute;
                    top: -14px;
                    bottom: 0;
                    right: -15px;
                    margin: auto;
                    width: 10px;
                    height: 100%;
                    color: $theme-color-wb;
                }
            }
        }

        .other {
            position: absolute;
            width: 36px;
            left: -8px;
            right: 0;
            padding: 20px 8px 5px;
            background: $theme-color-by;
            margin: auto;
            display: none;
            z-index: 9;
        }
    }
}