.titlebar {
    position: relative;
    height: 175px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    text-align: right;

    h1 {
        font-size: 2.5em;
        color: $theme-color-wy;
        text-transform: capitalize;
    }

    &::before {
        content: '';
        position: absolute;
        @include fillit(5,6,8,11,11,0);
        right: 0;
        height: 175px;
        z-index: 0;
        border-bottom: 175px solid rgba($theme-color-bb, 0.6);
        border-left: 45px solid transparent;
    }
}

.bredcrumbs {
    list-style: none;
    padding-left: 0;
    display: inline-block;
    color: $theme-color-wy;
    margin-bottom: 0;

    &__item {
        display: inline-block;
        text-transform: capitalize;
    }
}