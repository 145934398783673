@mixin fillit($xl:12, $lg:12, $md:12, $sm:12, $xs:12, $extra-space:15){
    $containers: (
        xs: 100%,
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
    );

    $padding: $extra-space + px;

    width: 100%;

    @include media-breakpoint-up(xs){
        @if $xs == f {
            width: 100vw;
        } @else {
            width: calc( ( (100vw - #{map-get($containers, xs)}) / 2 ) + ( (#{map-get($containers, xs)} * (#{$xs} / 12))) - #{$padding});
        }
    }
    @include media-breakpoint-up(sm){
        @if $sm == f {
            width: 100vw;
        } @else {
            width: calc( ( (100vw - #{map-get($containers, sm)}) / 2 ) + ( (#{map-get($containers, sm)} * (#{$sm} / 12))) - #{$padding});
        }
    }
    @include media-breakpoint-up(md){
        @if $md == f {
            width: 100vw;
        } @else {
            width: calc( ( (100vw - #{map-get($containers, md)}) / 2 ) + ( (#{map-get($containers, md)} * (#{$md} / 12))) - #{$padding});
        }
    }
    @include media-breakpoint-up(lg){
        @if $lg == f {
            width: 100vw;
        } @else {
            width: calc( ( (100vw - #{map-get($containers, lg)}) / 2 ) + ( (#{map-get($containers, lg)} * (#{$lg} / 12))) - #{$padding});
        }
    }
    @include media-breakpoint-up(xl){
        @if $xl == f {
            width: 100vw;
        } @else {
            width: calc( ( (100vw - #{map-get($containers, xl)}) / 2 ) + ( (#{map-get($containers, xl)} * (#{$xl} / 12))) - #{$padding});
        }
    }
}

@mixin single-negativ-margin($direction: left, $extra-space:15){
    $containers: (
        xs: 100%,
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
    );

    $padding: $extra-space + px;

    #{$direction}: 0;

    @include media-breakpoint-up(xs){
        #{$direction}: calc(( (#{map-get($containers, xs)} - 100vw) / 2 ) - #{$padding});
    }
    @include media-breakpoint-up(sm){
        #{$direction}: calc(( (#{map-get($containers, sm)} - 100vw) / 2 ) - #{$padding});
    }
    @include media-breakpoint-up(md){
        #{$direction}: calc(( (#{map-get($containers, md)} - 100vw) / 2 ) - #{$padding});
    }
    @include media-breakpoint-up(lg){
        #{$direction}: calc(( (#{map-get($containers, lg)} - 100vw) / 2 ) - #{$padding});
    }
    @include media-breakpoint-up(xl){
        #{$direction}: calc(( (#{map-get($containers, xl)} - 100vw) / 2 ) - #{$padding});
    }
}