.btn {
    font-size: 1em;
    position: relative;
    padding: .6rem 2.5rem;
    color: #fff;
    border-radius: 0px;
    border: none;
    transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 9;

    &:hover {
        color: #fff;
    }

    &--violet {
        color: $theme-color-wb;
        background: $theme-color-vy;

        &:hover {
            color: $theme-color-wb;
            transform: translateY(-5px);
            box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
        }
    }

    &--blue {
        background: $theme-color-by;
    }

}