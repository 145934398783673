.ua {
    // Header
    header {
        position: relative;
        color: #203F81;
        max-height: 135px;

        &::before {
            display: none;
        }

        .navbar {
            height: auto;
        }

        .brand {
            img {
                max-height: 120px;
            }
        }

        .languages {
            a {
                display: inline-block;
                margin: 0px 4px;
                width: 25px;
            }
        }
    }

    // Posters
    .home {
        height: auto;
        
        @include media-breakpoint-up(lg){
            height: 560px;
        }
        @include media-breakpoint-up(xl){
            height: 520px;
        }

        &__image{
            background: url('./../img/content/slider-wszuie.jpg') no-repeat center/cover;
            height: 100%;
            min-height: 350px;
        }

        &__form {
            text-align: center;
            color: #fff;
            background: #203F81;

            .contact {
                padding: 0 2rem;

                .form-control {
                    font-size: 14px;
                    height: 35px;
                    border-radius: 0;
                    border: none;
                }

                select {
                    width: 100%;
                    height: 35px;
                    font-size: 14px;
                    color: #8a8893;
                }
                
                textarea {
                    width: 100%;
                    height: 90px;
                }
            }
        }
    }

    // Study
    .nav-tabs {
        border-bottom: 6px solid #79006b;
        list-style: none;

        .nav-link {
            border: none;
            border-radius: 0;
            text-decoration: none;
            font-size: 1.2rem;
            padding: .5rem 1.5rem;

            &.active {
                color: #fff;
                background: #79006b;
            }
        }
    }
}