.section--download {
    table {
        tr {
            td {
                color: $theme-color-dd;
                &:nth-child(1){
                    font-weight: 700;
                }
                &:nth-child(2){
                    font-weight: 700;
                    color: $theme-color-by;
                }
                &:nth-child(4){
                    font-weight: 700;
                    color: $theme-color-vy;
                }
            }
        }
    }
}