header {
    position: relative;

    @include media-breakpoint-up(xl){
        &::before {
            content: '';
            position: absolute;
            @include fillit(9,9,9,9,9,24);
            right: 0;
            height: 60px;
            border-bottom: 60px solid $theme-color-by;
            border-left: 20px solid transparent;
            z-index: -1;
        }    
    }
    // overflow: hidden;
}