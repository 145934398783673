html {
	@import './../themes/default';
	scroll-behavior: smooth;
}

html.theme--yellow {
	@import './../themes/yellow';
	scroll-behavior: smooth;
}

body {
	position: relative;
	font-family: $font-prime;
	height: 100%;
	line-height: 1.65;
	color: #000;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
}

