.hr {
    &--full{
        margin: 0;
        border-top: 6px solid;
    }
    &--violet {
        border-color: $theme-color-vy;
    }
    &--contrast {
        border-top: 1px solid $theme-color-vy;
    }
}

.text-color-by {
    color: $theme-color-by;
}
.text-color-vy {
    color: $theme-color-vy;
}
.text-color-bw {
    color: $theme-color-wb !important;
}
.text-color-gray {
    color: $theme-color-gray;
}

.bg-color-vy {
    background: $theme-color-vy !important;
}

// Animations

@keyframes slideNav {
    0% {
        transform: translateX(-1rem);
        opacity: 0;
    }
    100% {
        transform:translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateX(-1rem);
        opacity: 0;
    }
}
  