body {
    font-size: 14px;
    background: $theme-color-wb;

    &.size-a {
		font-size: 12px;
	}
	&.size-aa {
		font-size: 14px;
	}
	&.size-aaa {
		font-size: 16px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

h3 {
	font-size: 2em;
}

a, a:hover{
    color: inherit;
    text-decoration: none;
}

ul {
    list-style: none;
}
